import Link from 'next/link'

import G2Icon from '@/components/icons/g2'
import InstagramIcon from '@/components/icons/instagram'
import LinkedinIcon from '@/components/icons/linkedin'

import Logo from './logo'

const Footer = () => {
  return (
    <footer className="font-poppins w-full bg-brand-950">
      <div className="mx-auto flex h-full max-w-container flex-col gap-24 px-4 pb-12 pt-16 sm:px-8 md:max-h-sm-screen md:px-12 lg:gap-12 lg:px-20">
        <div className="flex flex-col justify-between gap-8 sm:flex-row">
          <div className="space-y-4 text-3xl text-white lg:text-3xl">
            <Logo />
            <p className="text-lg font-extralight">
              E-Commerce Analytics Solution <br />{' '}
              <strong className="font-light">for Profitable Growth</strong>
            </p>
          </div>
          <div className="flex flex-col items-start gap-6 sm:gap-8 md:flex-row lg:gap-20">
            <div className="flex flex-col items-start gap-4 sm:gap-4 md:gap-6">
              <h2 className="text-lg font-medium text-brand-50">
                Our Solutions
              </h2>
              <ul className="ml-0 flex flex-col gap-4">
                <li className="flex">
                  <Link
                    href="/solutions/price"
                    className="text-sm text-brand-100"
                  >
                    Price
                  </Link>
                </li>
                <li className="flex">
                  <Link
                    href="/solutions/visibility"
                    className="text-sm text-brand-100"
                  >
                    Visibility
                  </Link>
                </li>
                <li className="flex">
                  <Link
                    href="/solutions/content"
                    className="text-sm text-brand-100"
                  >
                    Content
                  </Link>
                </li>
                <li className="flex">
                  <Link
                    href="/solutions/rating-review"
                    className="text-sm text-brand-100"
                  >
                    Rating & Review
                  </Link>
                </li>
                <li className="flex">
                  <Link
                    href="/solutions/location-based-analytics"
                    className="text-sm text-brand-100"
                  >
                    Location-Based Analytics
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-start gap-4 md:gap-6">
              <h2 className="text-lg font-medium text-brand-50">
                <Link href="/company">Company</Link>
              </h2>
            </div>
            <div className="flex flex-col items-start gap-4 sm:gap-6 md:gap-8 lg:gap-12">
              <h2 className="text-lg font-medium text-brand-50">
                <Link href="/team">Team</Link>
              </h2>
            </div>
            <div className="flex flex-col items-start gap-4 sm:gap-6 md:gap-8 lg:gap-12">
              <h2 className="text-lg font-medium text-brand-50">
                <Link href="/contact-us">Contact Us</Link>
              </h2>
            </div>
          </div>
        </div>
        <div className="flex flex-col divide-y divide-white">
          <div className="flex flex-col items-start gap-12 pb-10">
            <div className="flex gap-2">
              <a
                href="https://www.linkedin.com/company/themindsite"
                className="text-5xl text-brand-50"
                target="_blank"
                rel="noreferrer"
                title="Follow us on LinkedIn"
              >
                <LinkedinIcon />
              </a>
              <a
                href="https://www.instagram.com/themindsite"
                className="text-5xl text-brand-50"
                target="_blank"
                rel="noreferrer"
                title="Follow us on Instagram"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.g2.com/products/mindsite/reviews"
                className="text-5xl text-brand-50"
                target="_blank"
                rel="noreferrer"
                title="Read our reviews on G2"
              >
                <G2Icon />
              </a>
            </div>
            <a
              href="mailto:info@themindsite.com"
              className="text-xl text-brand-100"
              target="_blank"
              rel="noreferrer"
            >
              info@themindsite.com
            </a>
          </div>
          <div className="flex flex-col-reverse items-start justify-between gap-8 pt-8 sm:items-center md:flex-row md:items-end">
            <small className="text-sm text-brand-100">
              {new Date().getFullYear()} Mindsite ©. All rights reserved.
            </small>
            <div className="flex flex-col gap-8 sm:flex-row sm:gap-12">
              <a
                href="/docs/cookie-notice.pdf"
                className="text-sm text-brand-100"
                target="_blank"
                rel="noreferrer"
                type="application/pdf"
              >
                Cookie Notice
              </a>
              <a
                href="/docs/privacy-policy.pdf"
                className="text-sm text-brand-100"
                target="_blank"
                rel="noreferrer"
                type="application/pdf"
              >
                Privacy Policy
              </a>
              <a
                href="/docs/personal-data-application-form.pdf"
                className="text-sm text-brand-100"
                target="_blank"
                rel="noreferrer"
                type="application/pdf"
              >
                Personal Data Application Form
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
