import 'keen-slider/keen-slider.min.css'

import clsx from 'clsx'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module
import { type HTMLAttributes, type ReactNode, useState } from 'react'

function Arrow(props: { left?: boolean; onClick: (e: any) => void }) {
  return (
    <svg
      className="cursor-pointer transition-all duration-200 ease-in-out hover:rounded-full hover:border hover:border-transparent hover:bg-white"
      onClick={props.onClick}
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          props.left
            ? 'M25.1312 38.1323L12.0696 25.0705M12.0696 25.0705L25.1312 12.0088M12.0696 25.0705L38.9316 25.0705'
            : 'M25.8707 12.0089L38.9324 25.0706M38.9324 25.0706L25.8707 38.1323M38.9324 25.0706H12.0703'
        }
        stroke="#52525B"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const SliderItem = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return <div className={clsx('keen-slider__slide', className)}>{children}</div>
}

const Slider = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  const [loaded, setLoaded] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    slides: {
      spacing: 10,
    },

    created() {
      setLoaded(true)
    },
  })

  return (
    <section ref={sliderRef} className="keen-slider relative">
      {children}
      {loaded && instanceRef.current && (
        <div className="max-sm:hidden">
          <div style={{ top: 'calc(50% - 25px)' }} className="absolute right-2">
            <Arrow
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            />
          </div>
          <div style={{ top: 'calc(50% - 25px)' }} className="absolute left-2">
            <Arrow
              left
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            />
          </div>
        </div>
      )}
    </section>
  )
}

export default Slider
