const G2Icon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.912 21.0987C21.8196 22.6308 22.7171 24.1453 23.614 25.6583C19.6426 28.6156 13.4639 28.973 8.88987 25.5674C3.6262 21.6452 2.76106 14.9527 5.57915 10.1352C8.82036 4.59405 14.8857 3.36859 18.791 4.26717C18.6853 4.49034 16.3464 9.21005 16.3464 9.21005C16.3464 9.21005 16.1615 9.22186 16.0569 9.22384C14.9026 9.27143 14.0429 9.53266 13.1214 9.99607C12.1104 10.5092 11.244 11.2554 10.5979 12.1693C9.95188 13.0834 9.54581 14.1374 9.41522 15.2393C9.27897 16.3567 9.43784 17.4895 9.87681 18.5307C10.248 19.4109 10.773 20.1926 11.4769 20.8526C12.5566 21.8661 13.8415 22.4936 15.3308 22.7013C16.7412 22.8983 18.0976 22.7033 19.3686 22.082C19.8454 21.8494 20.2509 21.5924 20.725 21.2399C20.7854 21.2018 20.8391 21.1536 20.912 21.0987Z"
      fill="currentColor"
    />
    <path
      d="M20.9207 7.665C20.6903 7.44446 20.4767 7.24098 20.264 7.0362C20.1372 6.91411 20.0151 6.7871 19.8851 6.66796C19.8386 6.62497 19.784 6.56622 19.784 6.56622C19.784 6.56622 19.8282 6.47499 19.8471 6.43757C20.0957 5.95218 20.4854 5.59741 20.9477 5.31516C21.4588 5.00069 22.0544 4.84068 22.659 4.85537C23.4327 4.87013 24.1521 5.05753 24.7591 5.56229C25.2072 5.93479 25.437 6.40738 25.4775 6.97154C25.5449 7.92329 25.1401 8.6522 24.336 9.1609C23.8636 9.46021 23.3541 9.69158 22.8433 9.96562C22.5615 10.1169 22.3206 10.2498 22.0453 10.5235C21.8031 10.7982 21.7912 11.0572 21.7912 11.0572L25.4512 11.0526V12.6381H19.8018C19.8018 12.6381 19.8018 12.5297 19.8018 12.4848C19.7802 11.7057 19.8737 10.9725 20.2404 10.2649C20.5779 9.61577 21.1023 9.14055 21.7321 8.77462C22.2174 8.4927 22.7283 8.25279 23.2145 7.97219C23.5144 7.79923 23.7263 7.54554 23.7246 7.17764C23.7246 6.86193 23.4884 6.58132 23.151 6.4937C22.3554 6.28496 21.5456 6.61808 21.1245 7.32631C21.0631 7.42969 21.0003 7.53242 20.9207 7.665Z"
      fill="currentColor"
    />
    <path
      d="M27.9997 19.4127L24.9154 14.2318H18.8119L15.7076 19.4662H21.8561L24.8901 24.6224L27.9997 19.4127Z"
      fill="currentColor"
    />
  </svg>
)

export default G2Icon
